/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    floppy: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 2H9v3h2z"/><path pid="1" d="M1.5 0h11.586a1.5 1.5 0 011.06.44l1.415 1.414A1.5 1.5 0 0116 2.914V14.5a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 14.5v-13A1.5 1.5 0 011.5 0M1 1.5v13a.5.5 0 00.5.5H2v-4.5A1.5 1.5 0 013.5 9h9a1.5 1.5 0 011.5 1.5V15h.5a.5.5 0 00.5-.5V2.914a.5.5 0 00-.146-.353l-1.415-1.415A.5.5 0 0013.086 1H13v4.5A1.5 1.5 0 0111.5 7h-7A1.5 1.5 0 013 5.5V1H1.5a.5.5 0 00-.5.5m3 4a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V1H4zM3 15h10v-4.5a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5z"/>',
    },
});
